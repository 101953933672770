import { AddressMap } from './types';

export const AvalancheAddresses: AddressMap = {
    BankXToken:           '0x2147F5c02c2869E8C2d8F86471d3d7500355d698',
    XSDToken:             '0x93Abe713002526D4cE708f890139f19313012f95',
    BankXPool:            '0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683',
    XSDPool:              '0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84',
    CollateralPool:       '0x2426E857269C43444fc09092589244fe9c4C63D0',
    PIDController:        '0x3F0E5111785ECF0D0E25bF32bbf1a1B458757fD8',
    RewardManager:        '0x2DcBB8BBAa044E89a5A000D4BF2451c3D7aca4fb',
    RouterAddress:        '0x68acb5148c8693FC416a8632cfdF221124E05692',
    Arbitrage:            '0xaaDAE9117dF8b5d584378a41a105CC4862A16E99',
    CertificateOfDeposit: '0x3B5092fd38649c45c5418c319f9bA1ee02f84672',
    BankXNFT:             '0x547BdCB35A04096D1AC45bdC98b0583211D38672',
    NFTBonus:             '0xcE1664AdA6F272D7B8886D2277E6Ea5FF4035EC7',
    NFTCollector:         '0xCfB66cf4a206e4e637bC2FFbE181bcb46bbAF1B5',
    XAGUSDPriceConsumer:  '0x7b51Dd3B546A9e4a2a894620eCa083af252C52Db',
    Proxy:                '0x4305FB66699C3B2702D4d05CF36551390A4c69C6', // this should not change
};
// 04/03/2025 0904PST
// Reward Manager:         0x2DcBB8BBAa044E89a5A000D4BF2451c3D7aca4fb
// Router:                 0x68acb5148c8693FC416a8632cfdF221124E05692

// 04/01/2025 1422PST
// PID:                    0x3F0E5111785ECF0D0E25bF32bbf1a1B458757fD8

// 02/28/2025 1413PST
// Router:                 0x46F1a43fBfdDdF26ce9fDF0e2190EC29C2252746

// 01/22/2025 1355PST
// Collateral Pool:        0x2426E857269C43444fc09092589244fe9c4C63D0

// 01/09/2025 1317PST
// BankX Token Address:    0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD Token Address:      0x93Abe713002526D4cE708f890139f19313012f95
// BankX Pool:             0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// XSD Pool:               0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// Collateral Pool:        0x6cd84BE502D959C9aD6E194747B8FCCa0065FBa3
// PID:                    0xb2ae29742A350F924b870E4aDBAbb967f5DeE8d4
// Reward Manager:         0x89414CA9D08DAe79A2d80eBcDF6B503a0759E72c
// Router:                 0x8eb9D9f503e368E80A8C6F84697d61C93CF63b8f
// Arbitrage:              0xaaDAE9117dF8b5d584378a41a105CC4862A16E99
// CD:                     0x3B5092fd38649c45c5418c319f9bA1ee02f84672
// BankXNFT:               0x547BdCB35A04096D1AC45bdC98b0583211D38672
// NFTBonus:               0xcE1664AdA6F272D7B8886D2277E6Ea5FF4035EC7
// NFTCollector:           0xCfB66cf4a206e4e637bC2FFbE181bcb46bbAF1B5

// 11/30/2024 1231DT
// BankX Token Address:    0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD Token Address:      0x93Abe713002526D4cE708f890139f19313012f95
// BankX Pool:             0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// XSD Pool:               0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// Collateral Pool:        0xa6A40D73B52E9A35C6591feEE449b3eA183ccc26
// PID:                    0xb2ae29742A350F924b870E4aDBAbb967f5DeE8d4
// Reward Manager:         0x89414CA9D08DAe79A2d80eBcDF6B503a0759E72c
// Router:                 0x8eb9D9f503e368E80A8C6F84697d61C93CF63b8f
// Arbitrage:              0xaaDAE9117dF8b5d584378a41a105CC4862A16E99
// CD:                     0x3B5092fd38649c45c5418c319f9bA1ee02f84672
// BankXNFT:               0x547BdCB35A04096D1AC45bdC98b0583211D38672
// NFTBonus:               0xcE1664AdA6F272D7B8886D2277E6Ea5FF4035EC7
// NFTCollector:           0xCfB66cf4a206e4e637bC2FFbE181bcb46bbAF1B5

// 10/07/2024 0811PDT
// BankX Token Address:    0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD Token Address:      0x93Abe713002526D4cE708f890139f19313012f95
// BankX Pool:             0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// XSD Pool:               0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// CollateralPool:         0x5aEa65a215Ea0D11caC061a6b096f4D94CA9d700
// PID controller address: 0x202f7F598765b78Ed53a268CCe4a4C9d1b391015
// Reward Manager:         0x89414CA9D08DAe79A2d80eBcDF6B503a0759E72c
// Router:                 0x8eb9D9f503e368E80A8C6F84697d61C93CF63b8f
// Arbitrage:              0xaaDAE9117dF8b5d584378a41a105CC4862A16E99
// CD:                     0x3B5092fd38649c45c5418c319f9bA1ee02f84672
// BankXNFT:               0x547BdCB35A04096D1AC45bdC98b0583211D38672
// NFTBonus:               0xcE1664AdA6F272D7B8886D2277E6Ea5FF4035EC7

// 10/04/2024 1524PDT
// BankX Token Address:    0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD Token Address:      0x93Abe713002526D4cE708f890139f19313012f95
// BankX Pool:             0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// XSD Pool:               0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// CollateralPool:         0x5aEa65a215Ea0D11caC061a6b096f4D94CA9d700
// PID controller address: 0x9C98056141FC87e70100f7046FD1B22729d907e2
// Reward Manager:         0x89414CA9D08DAe79A2d80eBcDF6B503a0759E72c
// Router:                 0x8eb9D9f503e368E80A8C6F84697d61C93CF63b8f
// Arbitrage:              0xaaDAE9117dF8b5d584378a41a105CC4862A16E99
// CD:                     0x3B5092fd38649c45c5418c319f9bA1ee02f84672
// BankXNFT:               0x547BdCB35A04096D1AC45bdC98b0583211D38672
// NFTBonus:               0xcE1664AdA6F272D7B8886D2277E6Ea5FF4035EC7

// 8/26/2024 1944 PDT
// BankX Token Address:    0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD Token Address:      0x93Abe713002526D4cE708f890139f19313012f95
// BankX WETH Pool:        0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// XSD WETH Pool:          0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// CollateralPool:         0xa6FD872F0F6cf467Cd0c2B8352d9E5046D6926A9
// PID:                    0xF6Db5A9078Fd50836202c0DC7b0980341b52F124
// Reward Manager:         0x89414CA9D08DAe79A2d80eBcDF6B503a0759E72c
// Router:                 0x8eb9D9f503e368E80A8C6F84697d61C93CF63b8f
// Arbitrage:              0xaaDAE9117dF8b5d584378a41a105CC4862A16E99
// CD:                     0x3B5092fd38649c45c5418c319f9bA1ee02f84672
// BankXNFT:               0x547BdCB35A04096D1AC45bdC98b0583211D38672
// NFTBonus:               0xcE1664AdA6F272D7B8886D2277E6Ea5FF4035EC7

// 06/22/2024 1044PDT
// BankX Token Address: 0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD Token Address:   0x93Abe713002526D4cE708f890139f19313012f95
// BankX WETH Pool:     0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// XSD WETH Pool:       0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// CollateralPool:      0x59cA927Ae4c900dC8091515191E39B010bec1118
// PID:                 0x45B07536b320297eBf525a87373A13dAf10b17f3
// Reward Manager:      0x2eFDB51571fd8838244C0Ff82D4A8F30e7ce240c
// Router:              0x4D396E2083D360B6705B0D0890362C126cfd18b9
// Arbitrage:           0xaaDAE9117dF8b5d584378a41a105CC4862A16E99
// CD:                  0x3B5092fd38649c45c5418c319f9bA1ee02f84672
// BankXNFT:            0x547BdCB35A04096D1AC45bdC98b0583211D38672
// NFTBonus:            0xcE1664AdA6F272D7B8886D2277E6Ea5FF4035EC7
// XAG Oracle:          0x7b51Dd3B546A9e4a2a894620eCa083af252C52Db
// AVAX Oracle:         0xCC34E05c66569358bABCA66B6258e9eb74a843A3

// 06/20/2024 0810PDT
// BankX Token Address: 0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD Token Address:   0x93Abe713002526D4cE708f890139f19313012f95
// BankX AVAX Pool:     0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// XSD AVAX Pool:       0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// CollateralPool:      0x59cA927Ae4c900dC8091515191E39B010bec1118
// PID:                 0x45B07536b320297eBf525a87373A13dAf10b17f3
// Reward Manager:      0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2
// Router:              0xABdAF10CCFe2bC9C0CC086671F70b2041b6FA076
// Arbitrage:           0xaaDAE9117dF8b5d584378a41a105CC4862A16E99
// CD:                  0x3B5092fd38649c45c5418c319f9bA1ee02f84672
// BankXNFT:            0x547BdCB35A04096D1AC45bdC98b0583211D38672
// NFTBonus:            0xcE1664AdA6F272D7B8886D2277E6Ea5FF4035EC7
// XAG Oracle:          0x7b51Dd3B546A9e4a2a894620eCa083af252C52Db
// AVAX Oracle:         0xCC34E05c66569358bABCA66B6258e9eb74a843A3

// 06/05/2024 2204PDT
// BankXToken:           0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSDToken:             0x93Abe713002526D4cE708f890139f19313012f95
// BankXPool:            0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// XSDPool:              0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// CollateralPool:       0x59cA927Ae4c900dC8091515191E39B010bec1118
// PIDController:        0x45B07536b320297eBf525a87373A13dAf10b17f3
// RewardManager:        0x06c222c5C417D4106C9b55458820CF8ccA86511e
// RouterAddress:        0xABdAF10CCFe2bC9C0CC086671F70b2041b6FA076
// Arbitrage:            0xaaDAE9117dF8b5d584378a41a105CC4862A16E99
// CertificateOfDeposit: 0x3B5092fd38649c45c5418c319f9bA1ee02f84672
// BankXNFT:             0x547BdCB35A04096D1AC45bdC98b0583211D38672
// NFTBonus:             0xcE1664AdA6F272D7B8886D2277E6Ea5FF4035EC7
// XAGUSDPriceConsumer:  0x7b51Dd3B546A9e4a2a894620eCa083af252C52Db
// Proxy:                0x4305FB66699C3B2702D4d05CF36551390A4c69C6 // this should not change

// 05/29/2024 0903PDT
// BankXToken:           '0x2147F5c02c2869E8C2d8F86471d3d7500355d698',
// XSDToken:             '0x93Abe713002526D4cE708f890139f19313012f95',
// BankXPool:            '0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683',
// XSDPool:              '0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84',
// CollateralPool:       '0x59cA927Ae4c900dC8091515191E39B010bec1118',
// PIDController:        '0x45B07536b320297eBf525a87373A13dAf10b17f3',
// RewardManager:        '0x06c222c5C417D4106C9b55458820CF8ccA86511e',
// RouterAddress:        '0xABdAF10CCFe2bC9C0CC086671F70b2041b6FA076',
// Arbitrage:            '0xaaDAE9117dF8b5d584378a41a105CC4862A16E99',
// CertificateOfDeposit: '0x3B5092fd38649c45c5418c319f9bA1ee02f84672',
// BankXNFT:             '0x547BdCB35A04096D1AC45bdC98b0583211D38672',
// NFTBonus:             '0xcE1664AdA6F272D7B8886D2277E6Ea5FF4035EC7',
// XAGUSDPriceConsumer:  '0x7b51Dd3B546A9e4a2a894620eCa083af252C52Db',
// Proxy:                '0x4305FB66699C3B2702D4d05CF36551390A4c69C6',

// 05/29/2024 0704PDT
// BankXToken:           '0x2147F5c02c2869E8C2d8F86471d3d7500355d698',
// XSDToken:             '0x93Abe713002526D4cE708f890139f19313012f95',
// BankXPool:            '0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683',
// XSDPool:              '0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84',
// CollateralPool:       '0x59cA927Ae4c900dC8091515191E39B010bec1118',
// PIDController:        '0x45B07536b320297eBf525a87373A13dAf10b17f3',
// RewardManager:        '0x06c222c5C417D4106C9b55458820CF8ccA86511e',
// RouterAddress:        '0xABdAF10CCFe2bC9C0CC086671F70b2041b6FA076',
// Arbitrage:            '0xaaDAE9117dF8b5d584378a41a105CC4862A16E99',
// CertificateOfDeposit: '0x3B5092fd38649c45c5418c319f9bA1ee02f84672',
// BankXNFT:             '0x547BdCB35A04096D1AC45bdC98b0583211D38672',
// NFTBonus:             '0xcE1664AdA6F272D7B8886D2277E6Ea5FF4035EC7',
// XAGUSDPriceConsumer:  '0x3Cfa92cF9b8b08188F1e05393A4B77dF83703fe3',
// Proxy:                '0x4305FB66699C3B2702D4d05CF36551390A4c69C6',

// 05/28/2024 0851PDT
// BankXToken:           '0x2147F5c02c2869E8C2d8F86471d3d7500355d698',
// XSDToken:             '0x93Abe713002526D4cE708f890139f19313012f95',
// BankXPool:            '0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683',
// XSDPool:              '0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84',
// CollateralPool:       '0x59cA927Ae4c900dC8091515191E39B010bec1118',
// PIDController:        '0x45B07536b320297eBf525a87373A13dAf10b17f3',
// RewardManager:        '0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2',
// RouterAddress:        '0xABdAF10CCFe2bC9C0CC086671F70b2041b6FA076',
// Arbitrage:            '0xaaDAE9117dF8b5d584378a41a105CC4862A16E99',
// CertificateOfDeposit: '0x3B5092fd38649c45c5418c319f9bA1ee02f84672',
// BankXNFT:             '0x547BdCB35A04096D1AC45bdC98b0583211D38672',
// NFTBonus:             '0xcE1664AdA6F272D7B8886D2277E6Ea5FF4035EC7',
// XAGUSDPriceConsumer:  '0x3Cfa92cF9b8b08188F1e05393A4B77dF83703fe3',
// Proxy:                '0x4305FB66699C3B2702D4d05CF36551390A4c69C6',

// 05/27/2024 0845PDT
// BankXToken:           '0x2147F5c02c2869E8C2d8F86471d3d7500355d698',
// XSDToken:             '0x93Abe713002526D4cE708f890139f19313012f95',
// BankXPool:            '0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683',
// XSDPool:              '0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84',
// CollateralPool:       '0x59cA927Ae4c900dC8091515191E39B010bec1118',
// PIDController:        '0x45B07536b320297eBf525a87373A13dAf10b17f3',
// RewardManager:        '0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2',
// RouterAddress:        '0x58421507d10A4c57a761E8AAd5382D5564A682F5',
// Arbitrage:            '0xaaDAE9117dF8b5d584378a41a105CC4862A16E99',
// CertificateOfDeposit: '0x3B5092fd38649c45c5418c319f9bA1ee02f84672',
// BankXNFT:             '0x547BdCB35A04096D1AC45bdC98b0583211D38672',
// NFTBonus:             '0xcE1664AdA6F272D7B8886D2277E6Ea5FF4035EC7',
// XAGUSDPriceConsumer:  '0x3Cfa92cF9b8b08188F1e05393A4B77dF83703fe3',
// Proxy:                '0x4305FB66699C3B2702D4d05CF36551390A4c69C6',

// 05/10/2024 2231 PDT
// BankX Token Address:     0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD Token Address:       0x93Abe713002526D4cE708f890139f19313012f95
// BankX WETH Pool:         0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// XSD WETH Pool:           0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// CollateralPool:          0x59cA927Ae4c900dC8091515191E39B010bec1118
// PID:                     0x45B07536b320297eBf525a87373A13dAf10b17f3
// Reward Manager:          0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2
// Router:                  0x58421507d10A4c57a761E8AAd5382D5564A682F5
// Arbitrage:               0xaaDAE9117dF8b5d584378a41a105CC4862A16E99
// CD:                      0x3B5092fd38649c45c5418c319f9bA1ee02f84672
// BankXNFT:                0x547BdCB35A04096D1AC45bdC98b0583211D38672
// NFTBonus:                0xcE1664AdA6F272D7B8886D2277E6Ea5FF4035EC7
