import { AddressMap } from './types';

export const OptimismMainnetAddresses: AddressMap = {
    BankXToken:           '0x93Abe713002526D4cE708f890139f19313012f95',
    XSDToken:             '0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683',
    BankXPool:            '0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84',
    XSDPool:              '0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2',
    CollateralPool:       '0x9d78ddf428DC3C43288b9F9727cd742890398657',
    PIDController:        '0xbc94A15b50ebe1853A6BCe93eaECD8705909460a',
    RewardManager:        '0x4f9c91292E6dE192de3a42028f8ce48689c62a61',
    RouterAddress:        '0x89414CA9D08DAe79A2d80eBcDF6B503a0759E72c',
    Arbitrage:            '0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C',
    CertificateOfDeposit: '0xc79461C653bCd0137D062ec87B9b821f7c9bCc26',
    BankXNFT:             '0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C',
    NFTBonus:             '0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC',
    NFTCollector:         '0xCfB66cf4a206e4e637bC2FFbE181bcb46bbAF1B5',
};
// 04/01/2025 1422PST
// PID:                    0xbc94A15b50ebe1853A6BCe93eaECD8705909460a

// 02/28/2025 1413PST
// Router:                 0x89414CA9D08DAe79A2d80eBcDF6B503a0759E72c

// 01/22/2025 1355PST
// Collateral Pool:        0x9d78ddf428DC3C43288b9F9727cd742890398657

// 01/09/2025 1317PST
// BankX Token Address:    0x93Abe713002526D4cE708f890139f19313012f95
// XSD Token Address:      0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// BankX Pool Address:     0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// XSD Pool Address:       0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2
// Collateral Pool:        0x3487C9D6C6200E18d91c5337341B406a289af94D
// PID:                    0x3f83f15521643De6aEFe7A16571332180bf09cD1
// Reward Manager:         0x4f9c91292E6dE192de3a42028f8ce48689c62a61
// Router:                 0xb17832d20dc343650949867f8622CC4f6772AfaC
// Arbitrage:              0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD:                     0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// NFT:                    0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:               0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC
// NFTCollector:           0xCfB66cf4a206e4e637bC2FFbE181bcb46bbAF1B5

// 11/30/2024 1231DT
// BankX Token Address:    0x93Abe713002526D4cE708f890139f19313012f95
// XSD Token Address:      0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// BankX Pool Address:     0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// XSD Pool Address:       0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2
// Collateral Pool:        0xBa4B592b4410eA5562E47f08CaE40c426AD1719c
// PID:                    0x3f83f15521643De6aEFe7A16571332180bf09cD1
// Reward Manager:         0x4f9c91292E6dE192de3a42028f8ce48689c62a61
// Router:                 0xb17832d20dc343650949867f8622CC4f6772AfaC
// Arbitrage:              0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD:                     0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// NFT:                    0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:               0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC
// NFTCollector:           0xCfB66cf4a206e4e637bC2FFbE181bcb46bbAF1B5

// 10/04/2024 1524PDT
// BankX Token Address:    0x93Abe713002526D4cE708f890139f19313012f95
// XSD Token Address:      0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// BankX Pool Address:     0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// XSD Pool Address:       0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2
// CollateralPool:         0xA6013450c9A50faF4F76B964701941618537123a
// PID controller address: 0x5997a08fD19bB3E397b25d41c1c10D2F2E0299F7
// Reward Manager:         0x4f9c91292E6dE192de3a42028f8ce48689c62a61
// Router:                 0xb17832d20dc343650949867f8622CC4f6772AfaC
// Arbitrage:              0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD:                     0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// NFT:                    0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:               0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC

// 8/26/2024 1944 PDT
// BankX Token Address: 0x93Abe713002526D4cE708f890139f19313012f95
// XSD Token Address:   0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// BankX Pool Address:  0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// XSD Pool Address:    0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2
// CollateralPool:      0xA6013450c9A50faF4F76B964701941618537123a
// PID:                 0xa386b0B77e79BE61DEFfD56ACa9De734712E0701
// Reward Manager:      0x4f9c91292E6dE192de3a42028f8ce48689c62a61
// Router:              0xb17832d20dc343650949867f8622CC4f6772AfaC
// Arbitrage:           0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD:                  0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// NFT:                 0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:            0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC

// 8/26/2024 1944 PDT
// BankX Token Address: 0x93Abe713002526D4cE708f890139f19313012f95
// XSD Token Address:   0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// BankX Pool Address:  0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// XSD Pool Address:    0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2
// CollateralPool:      0x516f6b1680bC2b6a626128De1c2A8Cc3dd72C4eA
// PID:                 0xeCB3E1076F4d112d3A943B2d3Cef6803E7eF84C2
// Reward Manager:      0x4f9c91292E6dE192de3a42028f8ce48689c62a61
// Router:              0xb17832d20dc343650949867f8622CC4f6772AfaC
// Arbitrage:           0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD:                  0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// NFT:                 0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:            0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC

// 02/27/2024 2027PST
// BankX Token Address: 0x93Abe713002526D4cE708f890139f19313012f95
// XSD Token Address:   0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// BankX Pool Address:  0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// XSD Pool Address:    0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2
// CollateralPool:      0x45B07536b320297eBf525a87373A13dAf10b17f3
// PID:                 0x58421507d10A4c57a761E8AAd5382D5564A682F5
// Reward Manager:      0x0626A71D29f85c0fC665612623991Aa2EA2EAB62
// Router:              0x82B403e35D393174f33Ac8c72147D2BB3dC5Cc06
// Arbitrage:           0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD:                  0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// NFT:                 0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:            0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC
