import { Globals } from "hooks/useGetGlobals";
import { Logger } from "./logging";
import { TokenLabels } from "./labels";
import { MAX_SLIPPAGE_VALUE, MIN_SLIPPAGE_VALUE } from "context/AppSettingsProvider";
import { getSwapKey } from "./exchange";


function customround(val: number, base = .005) {
    const inv = 1.0 / base;
    const res = Math.ceil(val * inv) / inv;
    Logger.log('[customround]:', val, '=>', res);
    return res;
}

export function getSlippageInfoFromGlobals(
    srcToken: string,
    targetToken: string,
    globals: Globals,
): {
    sourceTotalTokens: number;
    targetTotalTokens: number;
} {
    const p = globals.poolTokenAmounts;
    let src = '0', target = '0';

    switch(getSwapKey(srcToken, targetToken)) {
        case getSwapKey(TokenLabels.Bankx, TokenLabels.Erc): {
            src = p.bankx;
            target = p.bankxEth;
            break;
        }
        case getSwapKey(TokenLabels.Erc, TokenLabels.Bankx): {
            src = p.bankxEth;
            target = p.bankx;
            break;
        }
        case getSwapKey(TokenLabels.Erc, TokenLabels.Xsd): {
            src = p.xsdEth;
            target = p.xsd;
            break;
        }
        case getSwapKey(TokenLabels.Xsd, TokenLabels.Erc): {
            src = p.xsd;
            target = p.xsdEth;
            break;
        }
    }

    return {
        sourceTotalTokens: Number(src),
        targetTotalTokens: Number(target),
    }
}

interface CalculateSlippageConfig {
    input: string;
    output: string;
    srcToken: string;
    targetToken: string;
    globals: Globals;
}

export function calculateSwapSlippage(config: CalculateSlippageConfig): string {
    const autoslippagemin = MIN_SLIPPAGE_VALUE / 100;
    const autoslippagecap = MAX_SLIPPAGE_VALUE / 100;
    const addedSlippage = .01; // always added another 1%

    const {
        // token A is taken to be the input token for the swap, numA is the total number of token A in the liquidity pool at the beginning of the swap.
        sourceTotalTokens: numA,
        // token B is taken to be the output token for the swap, numB is the total number of token B in the liquidity pool at the beginning of the swap.
        targetTotalTokens: numB,
    } = getSlippageInfoFromGlobals(config.srcToken, config.targetToken, config.globals);

    // constant product formula
    const k = numA * numB;

    // Number of token B required to purchase 1 of token A in the liquidity pool at the beginning of the swap.
    const initialprice = numB / numA;

    // The number of token A that actually is used for the trade. If any additional fees exist beyond gas fees, subtract them here.
    // **we don't care about gas fees though. That is calculated separately
    const input = Number(config.input);

    // Number of token B in the liquidity pool after the swap is completed.
    const newnumB = k/(numA + input);

    // We already have this but we can use this value for comparison
    const output = numB - newnumB;

    const newprice = newnumB/(numA + input)
    const priceimpact = initialprice/newprice - 1;
    const autoslippage = Math.max(autoslippagemin, Math.min(autoslippagecap, customround(2*priceimpact) + addedSlippage));

    Logger.log('[calculateSwapSlippage]: ', {
        autoslippagecap,
        numA,
        numB,
        k,
        initialprice,
        input,
        newnumB,
        calculatedOutput: output,
        expectedOutput: config.output,
        newprice,
        priceimpact,
        autoslippage,
    });

    return String(autoslippage * 100);
}
