import { AddressMap } from './types';

export const BaseAddresses: AddressMap = {
    BankXToken:           '0x2147F5c02c2869E8C2d8F86471d3d7500355d698',
    XSDToken:             '0x93Abe713002526D4cE708f890139f19313012f95',
    BankXPool:            '0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683',
    XSDPool:              '0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84',
    CollateralPool:       '0xA6013450c9A50faF4F76B964701941618537123a',
    PIDController:        '0xec4241C8522EE7cbEc361807c93b33f50D1D0c65',
    RewardManager:        '0x58421507d10A4c57a761E8AAd5382D5564A682F5',
    RouterAddress:        '0x7942418efD38dd2e0C6E13574C168B3d2E7C0A3E',
    Arbitrage:            '0xaaDAE9117dF8b5d584378a41a105CC4862A16E99',
    CertificateOfDeposit: '0xc79461C653bCd0137D062ec87B9b821f7c9bCc26',
    BankXNFT:             '0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C',
    NFTBonus:             '0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC',
    NFTCollector:         '0xCfB66cf4a206e4e637bC2FFbE181bcb46bbAF1B5',
    Proxy:                '0x8250f4aF4B972684F7b336503E2D6dFeDeB1487a',
};
// 04/03/2025 0904PST
// PID:                   0xec4241C8522EE7cbEc361807c93b33f50D1D0c65

// 04/01/2025 1422PST
// PID:                   0xCC34E05c66569358bABCA66B6258e9eb74a843A3

// 02/28/2025 1413PST
// Router:                0x7942418efD38dd2e0C6E13574C168B3d2E7C0A3E

// 01/22/2025 1355PST
// Collateral Pool:       0xA6013450c9A50faF4F76B964701941618537123a

// 12/30/2024 2029PST
// BankX Token address:  0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD Token address:    0x93Abe713002526D4cE708f890139f19313012f95
// BankX Pool:           0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// XSD Pool:             0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// Collateral Pool:      0x7608aa0791B06A57a3BCC2860176357575c68Cf5
// PID:                  0x45B07536b320297eBf525a87373A13dAf10b17f3
// Reward Manager:       0x58421507d10A4c57a761E8AAd5382D5564A682F5
// Router:               0x59cA927Ae4c900dC8091515191E39B010bec1118
// Arbitrage:            0xaaDAE9117dF8b5d584378a41a105CC4862A16E99
// Token LockUp Rewards: 0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankX NFT:            0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:             0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC
// NFTCollector:         0xCfB66cf4a206e4e637bC2FFbE181bcb46bbAF1B5
// Proxy:                0x8250f4aF4B972684F7b336503E2D6dFeDeB1487a
// ethusd:               0xeeA52F6587F788cc12d0b5a28c48e61866c076F0
// xagusd:               0xAbAc1C04408Cb6509BC9340e6b033c17F161Ef9e
// Base WETH:            0x4200000000000000000000000000000000000006

// 12/29/2024 2014PST
// BankX Token address:  0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD Token address:    0x93Abe713002526D4cE708f890139f19313012f95
// BankX Pool:           0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// XSD Pool:             0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// Collateral Pool:      0xF37c90E8453046684e91588a5779D2924F876231
// PID:                  0x45B07536b320297eBf525a87373A13dAf10b17f3
// Reward Manager:       0x58421507d10A4c57a761E8AAd5382D5564A682F5
// Router:               0x59cA927Ae4c900dC8091515191E39B010bec1118
// Arbitrage:            0xaaDAE9117dF8b5d584378a41a105CC4862A16E99
// Token LockUp Rewards: 0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankX NFT:            0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:             0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC
// NFTCollector:         0xCfB66cf4a206e4e637bC2FFbE181bcb46bbAF1B5
// Proxy:                0x8250f4aF4B972684F7b336503E2D6dFeDeB1487a
// ethusd:               0xeeA52F6587F788cc12d0b5a28c48e61866c076F0
// xagusd:               0xAbAc1C04408Cb6509BC9340e6b033c17F161Ef9e
// Base WETH:            0x4200000000000000000000000000000000000006

// 12/12/2024 1444PST
// BankX Token address:  0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD Token address:    0x93Abe713002526D4cE708f890139f19313012f95
// BankX Pool:           0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// XSD Pool:             0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// Collateral Pool:      0x516f6b1680bC2b6a626128De1c2A8Cc3dd72C4eA
// PID:                  0x45B07536b320297eBf525a87373A13dAf10b17f3
// Reward Manager:       0x58421507d10A4c57a761E8AAd5382D5564A682F5
// Router:               0x59cA927Ae4c900dC8091515191E39B010bec1118
// Arbitrage:            0xaaDAE9117dF8b5d584378a41a105CC4862A16E99
// Token LockUp Rewards: 0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankX NFT:            0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:             0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC
// Proxy:                0x8250f4aF4B972684F7b336503E2D6dFeDeB1487a
// ethusd:               0xeeA52F6587F788cc12d0b5a28c48e61866c076F0
// xagusd:               0xAbAc1C04408Cb6509BC9340e6b033c17F161Ef9e
// Base WETH:            0x4200000000000000000000000000000000000006

// 12/4/2024 2102PST
// BankX Token address:  0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD Token address:    0x93Abe713002526D4cE708f890139f19313012f95
// BankX Pool:           0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// XSD Pool:             0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// Collateral Pool:      0x0626A71D29f85c0fC665612623991Aa2EA2EAB62
// PID:                  0x45B07536b320297eBf525a87373A13dAf10b17f3
// Reward Manager:       0x58421507d10A4c57a761E8AAd5382D5564A682F5
// Router:               0x59cA927Ae4c900dC8091515191E39B010bec1118
// Arbitrage:            0xaaDAE9117dF8b5d584378a41a105CC4862A16E99
// Token LockUp Rewards: 0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankX NFT:            0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:             0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC
// Proxy:                0x8250f4aF4B972684F7b336503E2D6dFeDeB1487a
// ethusd:               0xeeA52F6587F788cc12d0b5a28c48e61866c076F0
// xagusd:               0xAbAc1C04408Cb6509BC9340e6b033c17F161Ef9e
// Base WETH:            0x4200000000000000000000000000000000000006

// 12/3/2024 1934PST
// BankX Token address:  0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD Token address:    0x93Abe713002526D4cE708f890139f19313012f95
// BankX Pool:           0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// XSD Pool:             0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// Collateral Pool:      0x0626A71D29f85c0fC665612623991Aa2EA2EAB62
// PID:                  0x45B07536b320297eBf525a87373A13dAf10b17f3
// Reward Manager:       0x58421507d10A4c57a761E8AAd5382D5564A682F5
// Router:               0x59cA927Ae4c900dC8091515191E39B010bec1118
// Arbitrage:            0xaaDAE9117dF8b5d584378a41a105CC4862A16E99
// Token LockUp Rewards: 0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankX NFT:            0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus:             0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC
// ethusd:               0xeeA52F6587F788cc12d0b5a28c48e61866c076F0
// xagusd:               0xAbAc1C04408Cb6509BC9340e6b033c17F161Ef9e
// Base WETH:            0x4200000000000000000000000000000000000006
