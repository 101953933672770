import { AddressMap } from './types';

export const ArbitrumOneAddresses: AddressMap = {
    BankXToken:           '0x93Abe713002526D4cE708f890139f19313012f95',
    XSDToken:             '0xA3034EF5f9D98f4dAC6Fc44421642c2616d9E346',
    BankXPool:            '0x1ff77d8e8e011bcf505cd4c6c110b53969fb5e84',
    XSDPool:              '0x0626A71D29f85c0fC665612623991Aa2EA2EAB62',
    CollateralPool:       '0x3fB96eBe83818BfE61A2b8c4baB42ec729eC63B3',
    PIDController:        '0x9f5f98657E714CfbB5Af899b722685E8E7F71B7D',
    RewardManager:        '0xF8305c1AA6bD5E743eb2Dd6889021a926b1A8351',
    RouterAddress:        '0x093e9C83Af18B1519099Cb90786eD584bAB0A733',
    Arbitrage:            '0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C',
    CertificateOfDeposit: '0xc79461C653bCd0137D062ec87B9b821f7c9bCc26',
    BankXNFT:             '0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C',
    NFTBonus:             '0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC',
    NFTCollector:         '0xCfB66cf4a206e4e637bC2FFbE181bcb46bbAF1B5',
};
// 03/25/2025 0834PST
// PID:              0x9f5f98657E714CfbB5Af899b722685E8E7F71B7D

// 03/24/2025 1355PST
// PID:              0x990aC7f15E12266C649338466F469c907844763D

// 03/23/2025 1010PST
// PID:              0x903a3C04203F4b4dB696486eCd7C9FDB8f765A05

// 03/11/2025 1948PDT
// PID:              0xe40802820C02C96cFf0d753A2b45E996615F9aee

// 03/10/2025 1948PDT
// PID:              0x4ABB6Ca3270dBB0f3bD558F83A71C5cfA45B4986

// 03/07/2025 1835PST
// PID:              0x61eDA62c78Be16Ed3BfdA4227006Fc8a92D8e01e

// 03/06/2025 1639PST
// PID:              0x542929804165121aBBCE1D667eCbDc322643f341

// 02/28/2025 1413PST
// Router:           0x093e9C83Af18B1519099Cb90786eD584bAB0A733

// 02/22/2025 1130PST
// Router:           0xE672504Df1d7bb75e2b1176662C46413560940b5

// 01/22/2025 1355PST
// Collateral Pool:  0x3fB96eBe83818BfE61A2b8c4baB42ec729eC63B3

// 01/09/2025 1317PST
// BankXToken:       0x93Abe713002526D4cE708f890139f19313012f95
// XSDToken:         0xA3034EF5f9D98f4dAC6Fc44421642c2616d9E346
// BankXPool:        0x1ff77d8e8e011bcf505cd4c6c110b53969fb5e84
// XSDPool:          0x0626A71D29f85c0fC665612623991Aa2EA2EAB62
// Collateral Pool:  0xCafEb3Dd19F644F06023C9064F8fd1f87Ac95e0A
// PID:              0x75c72ea2925aE17c59B2e9fD7651f986fAF1673f
// RewardManager:    0xF8305c1AA6bD5E743eb2Dd6889021a926b1A8351
// Router:           0x00515Ca4807EE57Da7d4e290257509bd25d6298F
// Arbitrage:        0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD address:       0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT address: 0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus address: 0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC
// NFTCollector:     0xCfB66cf4a206e4e637bC2FFbE181bcb46bbAF1B5

// 11/30/2024 1231DT
// BankXToken:       0x93Abe713002526D4cE708f890139f19313012f95
// XSDToken:         0xA3034EF5f9D98f4dAC6Fc44421642c2616d9E346
// BankXPool:        0x1ff77d8e8e011bcf505cd4c6c110b53969fb5e84
// XSDPool:          0x0626A71D29f85c0fC665612623991Aa2EA2EAB62
// Collateral Pool:  0xf62654B7491E7843FE6270f9Db2aC0D3Be6053D7
// PID:              0x75c72ea2925aE17c59B2e9fD7651f986fAF1673f
// RewardManager:    0xF8305c1AA6bD5E743eb2Dd6889021a926b1A8351
// Router:           0x00515Ca4807EE57Da7d4e290257509bd25d6298F
// Arbitrage:        0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD address:       0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT address: 0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus address: 0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC
// NFTCollector:     0xCfB66cf4a206e4e637bC2FFbE181bcb46bbAF1B5

// 10/04/2024 1524PDT
// BankXToken:       0x93Abe713002526D4cE708f890139f19313012f95
// XSDToken:         0xA3034EF5f9D98f4dAC6Fc44421642c2616d9E346
// BankXPool:        0x1ff77d8e8e011bcf505cd4c6c110b53969fb5e84
// XSDPool:          0x0626A71D29f85c0fC665612623991Aa2EA2EAB62
// CollateralPool:   0x9d78ddf428DC3C43288b9F9727cd742890398657
// PID Controller:   0x8DA85B822aC8767F6A987F0A662039734CC4f066
// RewardManager:    0xF8305c1AA6bD5E743eb2Dd6889021a926b1A8351
// Router:           0x00515Ca4807EE57Da7d4e290257509bd25d6298F
// Arbitrage:        0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD address:       0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT address: 0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus address: 0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC

// 10/2/2024 1018PDT
// BankXToken:      0x93Abe713002526D4cE708f890139f19313012f95
// XSDToken:        0xA3034EF5f9D98f4dAC6Fc44421642c2616d9E346
// BankXPool:       0x1ff77d8e8e011bcf505cd4c6c110b53969fb5e84
// XSDPool:         0x0626A71D29f85c0fC665612623991Aa2EA2EAB62
// CollateralPool:  0x9d78ddf428DC3C43288b9F9727cd742890398657
// PIDController:   0xed9012D1d6Ae24249BC38fB7Fe2Cc298A926665a
// RewardManager:   0xF8305c1AA6bD5E743eb2Dd6889021a926b1A8351
// Router:          0x00515Ca4807EE57Da7d4e290257509bd25d6298F
// Arbitrage:       0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD address       0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT address 0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus address 0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC

// 8/26/2024 1944 PDT
// BankXToken:      0x93Abe713002526D4cE708f890139f19313012f95
// XSDToken:        0xA3034EF5f9D98f4dAC6Fc44421642c2616d9E346
// BankXPool:       0x1ff77d8e8e011bcf505cd4c6c110b53969fb5e84
// XSDPool:         0x0626A71D29f85c0fC665612623991Aa2EA2EAB62
// CollateralPool:  0x5997a08fD19bB3E397b25d41c1c10D2F2E0299F7
// PIDController:   0x0C241B8d1e8de02e6627229b6fB291B82efd3B8e
// RewardManager:   0xF8305c1AA6bD5E743eb2Dd6889021a926b1A8351
// Router:          0x00515Ca4807EE57Da7d4e290257509bd25d6298F
// Arbitrage:       0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD address       0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT address 0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus address 0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC

// 02/18/2024 - 2020PST
// BankXToken:      0x93Abe713002526D4cE708f890139f19313012f95
// XSDToken:        0xA3034EF5f9D98f4dAC6Fc44421642c2616d9E346
// BankXPool:       0x1ff77d8e8e011bcf505cd4c6c110b53969fb5e84
// XSDPool:         0x0626A71D29f85c0fC665612623991Aa2EA2EAB62
// CollateralPool:  0x45B07536b320297eBf525a87373A13dAf10b17f3
// PIDController:   0x58421507d10A4c57a761E8AAd5382D5564A682F5
// RewardManager:   0x7b51Dd3B546A9e4a2a894620eCa083af252C52Db
// Router:          0x82B403e35D393174f33Ac8c72147D2BB3dC5Cc06
// Arbitrage:       0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD address       0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT address 0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus address 0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC

// 02/09/2024 - 2100PST
// BankXToken:      0x93Abe713002526D4cE708f890139f19313012f95
// XSDToken:        0xA3034EF5f9D98f4dAC6Fc44421642c2616d9E346
// BankXPool:       0x1ff77d8e8e011bcf505cd4c6c110b53969fb5e84
// XSDPool:         0x0626A71D29f85c0fC665612623991Aa2EA2EAB62
// CollateralPool:  0x45B07536b320297eBf525a87373A13dAf10b17f3
// PIDController:   0x58421507d10A4c57a761E8AAd5382D5564A682F5
// RewardManager:   0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2
// Router:          0x82B403e35D393174f33Ac8c72147D2BB3dC5Cc06
// Arbitrage:       0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD address       0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT address 0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus address 0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC
