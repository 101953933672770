import { ModalConfig } from 'context/ModalContext';
import { Contract } from 'helpers/addresses';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';
import { Logger } from './logging';
import { Paths } from './paths';

// this determines which action gives the most bankx
export enum Action {
    XsdLiquidity = 'xsd-liquidity', // Selling liquidity to XSD pool earns 9 bankx
    BankxLiquidity = 'bankx-liquidity', // Selling liquidity for Bankx pool earns 8 bankx
    CollateralDeficit = 'collateral-deficit', // Adding to collateral pool deficit earns 7 bankx
    Swap = 'swap', // only swap can grant more bankx
}

export interface PreferredBankxAction {
    action: Action;
    redirectPath: string;
    redirectOptions: NavigateOptions;
}

export function getPreferredBankxAction(
    xsdPoolMaxContribution: number | string,
    bankxPoolMaxContribution: number | string,
    collateralMaxContribution: number | string,
): PreferredBankxAction {
    Logger.info(`getPreferredBankxAction: xsd=${xsdPoolMaxContribution} bankx=${bankxPoolMaxContribution} collateral=${collateralMaxContribution}`);

    if (Number(xsdPoolMaxContribution) > 0) {
        return {
            action: Action.XsdLiquidity,
            redirectPath: '/liquidity-pool',
            redirectOptions: {
                state: {
                    targetPool: Contract.XSDPool,
                }
            },
        };

    } else if (Number(bankxPoolMaxContribution) > 0) {
        return {
            action: Action.BankxLiquidity,
            redirectPath: '/liquidity-pool',
            redirectOptions: {
                state: {
                    targetPool: Contract.BankXPool,
                }
            },
        };

    } else if (Number(collateralMaxContribution) > 0) {
        return {
            action: Action.CollateralDeficit,
            redirectPath: '/collateral',
            redirectOptions: {},
        };
    }

    return {
        action: Action.Swap,
        redirectPath: '/swap',
        redirectOptions: {},
    };
}

const enabledPages = new Set<string>([
    Paths.Collateral,
    Paths.Liquidity,
    Paths.Swap,
]);

export function useBankxActionModalConfig(
    bankxAction: PreferredBankxAction | null,
): ModalConfig | null {

    const { pathname, hash } = useLocation();
    const navigate = useNavigate();

    Logger.log('useBankxActionModalConfig: bankxAction', bankxAction);

    if (!bankxAction) {
        return null;
    }

    // TODO: disable this modal only for now
    const baseConfig = {
        name: Paths.Swap,
        cta: 'You get more BankX if you SELL LIQUIDITY rather than SWAP',
        acceptMessage: 'Sell Liquidity',
        declineMessage: 'No, I don’t want more BankX',
        checkboxMessage: 'Never show this message again',
        onAccept: () => navigate(bankxAction.redirectPath, bankxAction.redirectOptions),
    };

    // the modal should only appear on enabled pages
    if (!enabledPages.has(pathname)) {
        return null;
    }

    // do nothing if user is already on the preferred page
    if (pathname === bankxAction.redirectPath) {
        return null;
    }

    // modal logic based on page
    switch (pathname) {
        case Paths.Swap: {
            switch (bankxAction.action) {
                // disable this for now; see https://ossllc.atlassian.net/browse/BAN-523
                case Action.XsdLiquidity:
                    return null;

                case Action.CollateralDeficit:
                    Logger.log('useBankxActionModalConfig: swap / collateral deficit');
                    return {
                        ...baseConfig,
                        cta: 'You get more BankX if you ADD COLLATERAL rather than SWAP',
                        acceptMessage: 'Add Collateral',
                    }

                default:
                    Logger.log('useBankxActionModalConfig: swap / liquidity');
                    return baseConfig;
            }
        }

        case Paths.Liquidity: {
            switch (bankxAction.action) {
                case Action.CollateralDeficit:
                     Logger.log('useBankxActionModalConfig: liquidity / collateral deficit');
                    return {
                        ...baseConfig,
                        name: 'liquidity',
                        cta: 'The BankX liquidity pools are fully funded but the collateral pool is in deficit, you can also earn rewards by adding to the collateral pool',
                        acceptMessage: 'OK',
                        declineMessage: '',
                        checkboxMessage: '',
                        cannotBeDisabled: true,
                    }

                case Action.Swap:
                     Logger.log('useBankxActionModalConfig: liquidity / swap deficit');
                    return {
                        ...baseConfig,
                        name: 'liquidity',
                        cta: 'There are no rewards being offered for BankX on this blockchain.  Check the others.  If you would like to buy BankX, you will need to use SWAP.',
                        acceptMessage: 'OK',
                        declineMessage: '',
                        checkboxMessage: '',
                        cannotBeDisabled: true,
                    }

                default:
                    return null;
            }
        }

        case Paths.Collateral: {
            Logger.log('useBankxActionModalConfig: collateral page');
            return baseConfig;
        }
    }

    Logger.log('useBankxActionModalConfig: hash=', hash);
    return baseConfig;
}
